var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"backgroundColor":_vm.colorBackground,"id":"sidebar"}},[_c('mobile-menu'),(_vm.menus.length > 0 && this.userLogged)?_c('template',{slot:"links"},[(_vm.isMenuVisible('/operativa'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Operativa',
          icon: 'nc-icon nc-delivery-fast',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/envios'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Envíos', path: '/franquicia/envios' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/control'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Control', path: '/franquicia/control' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/rma'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'RMA', path: '/franquicia/rma' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/envios-agrupados'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Envíos Agrupados',
            path: '/franquicia/envios-agrupados',
          }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/recogidas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Recogidas',
          icon: 'nc-icon nc-notes',
          path: '*',
        }}},[(_vm.isMenuVisible('/recogidas/pendiente_recogida'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Solicitadas',
            path: '/recogidas/pendiente_recogida',
          }}}):_vm._e(),(_vm.isMenuVisible('/recogidas/incidencia_recogida'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Con Incidencia',
            path: '/recogidas/incidencia_recogida',
          }}}):_vm._e(),(_vm.isMenuVisible('/recogidas/recogida_finalizada'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Finalizadas',
            path: '/recogidas/recogida_finalizada',
          }}}):_vm._e(),(_vm.isMenuVisible('/recogidas/propias_otras_plazas_recogida'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Otras Plazas',
            path: '/recogidas/propias_otras_plazas_recogida',
          }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/entregas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Entregas',
          icon: 'nc-icon nc-app',
          path: '*',
        }}},[(_vm.isMenuVisible('/entregas/depositado_en_destino'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Ptes Asignar',
            path: '/entregas/depositado_en_destino',
          }}}):_vm._e(),(_vm.isMenuVisible('/entregas/en_reparto'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Ptes Entregar',
            path: '/entregas/en_reparto',
          }}}):_vm._e(),(_vm.isMenuVisible('/entregas/incidencia_entrega'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Con Incidencia',
            path: '/entregas/incidencia_entrega',
          }}}):_vm._e(),(_vm.isMenuVisible('/entregas/entregado'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Finalizadas',
            path: '/entregas/entregado',
          }}}):_vm._e(),(_vm.isMenuVisible('/entregas/propias_otras_plazas_entrega'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Otras Plazas',
            path: '/entregas/propias_otras_plazas_entrega',
          }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/movilidad'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Movilidad',
          icon: 'nc-icon nc-compass-05',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/trafico'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'General',
            path: '/franquicia/trafico',
          }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/recogidas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Recogidas', path: '/franquicia/recogidas' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/entregas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Entregas',
            path: '/franquicia/entregas',
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Partner',
          icon: 'nc-icon nc-globe-2',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/partner/salidas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Salidas',
            path: '/franquicia/partner/salidas',
          }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/partner/salidas-programadas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Salidas programadas',
            path: '/franquicia/partner/salidas-programadas',
          }}}):_vm._e()],1),(_vm.isMenuVisible('/incidencias'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Gestión de Incidencias',
          icon: 'nc-icon nc-settings-tool-66',
          path: '/franquicia/incidencias/historico',
        }}}):_vm._e(),(_vm.isMenuVisible('/importaciones'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Importaciones',
          icon: 'nc-icon nc-cloud-upload-94',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/importaciones'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Envios', path: '/franquicia/importaciones' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/importacionesRma'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'RMA', path: '/franquicia/importacionesRma' }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/clientes'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Clientes',
          icon: 'nc-icon nc-circle-09',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/clientes'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Listado de Clientes',
            path: '/franquicia/clientes',
          }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/facturacion'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Facturación',
          icon: 'nc-icon nc-paper-2',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/valoraciones'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Control de Valoraciones',
            path: '/franquicia/valoraciones',
          }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/albaranes'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Albaranes', path: '/franquicia/albaranes' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/facturas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Facturas', path: '/franquicia/facturas' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/tarifas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Tarifas', path: '/franquicia/tarifas' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/notificaciones'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
            name: 'Notificaciones',
            path: '/franquicia/notificaciones',
          }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/gestion_de_cartera'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Gestión de Cartera',
          icon: 'nc-icon nc-credit-card',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/efectos'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Efectos', path: '/franquicia/efectos' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/remesas'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Remesas', path: '/franquicia/remesas' }}}):_vm._e()],1):_vm._e(),(_vm.isMenuVisible('/franquicia'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{
          name: 'Franquicia',
          icon: 'nc-icon nc-single-02',
          path: '*',
        }}},[(_vm.isMenuVisible('/franquicia/misDatos'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Mis Datos', path: '/franquicia/misDatos' }}}):_vm._e(),(_vm.isMenuVisible('/franquicia/usuarios'))?_c('sidebar-item',{attrs:{"hideItem":_vm.ocultarItem,"link":{ name: 'Usuarios', path: '/franquicia/usuarios' }}}):_vm._e()],1):_vm._e()],1):_vm._e()],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{on:{"click":_vm.toggleSidebar}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }